import { State } from "../router/state";

/**
 * @see https://nhsconnect.github.io/nhsapp-developer-documentation/web-integration-step-by-step/
 */
export class NhsAuthenticationError extends Error {
  readonly error: string;
  readonly errorDescription: string;
  readonly userState: State | undefined;

  constructor({
    error,
    errorDescription,
    userState,
  }: {
    error: string;
    errorDescription: string;
    userState?: State;
  }) {
    super(
      errorDescription === "ConsentNotGiven"
        ? "User did not consent to share their NHS login information with Zymego."
        : "User is not authorized to access Zymego.",
    );

    this.error = error;
    this.errorDescription = errorDescription;
    this.name = NhsAuthenticationError.name;
    this.userState = userState;
  }
}
