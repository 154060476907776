import { Location } from "react-router-dom";

import { isObject } from "../utils";

type LocationState = {
  pathname: string;
  search?: string;
  state?: unknown;
};

type State = StateWithBackgroundLocation | StateWithLocationState;

type StateWithBackgroundLocation = {
  backgroundLocation: Location;
};

type StateWithLocationState = {
  locationState: LocationState;
};

function isLocation(value: unknown): value is Location {
  return (
    isObject(value) &&
    "hash" in value &&
    typeof value.hash === "string" &&
    "key" in value &&
    typeof value.key === "string" &&
    "pathname" in value &&
    typeof value.pathname === "string" &&
    "search" in value &&
    typeof value.search === "string" &&
    "state" in value
  );
}

function isLocationState(value: unknown): value is LocationState {
  return (
    isObject(value) &&
    "pathname" in value &&
    typeof value.pathname === "string" &&
    (!("search" in value) || typeof value.search === "string")
  );
}

function isState(state: unknown): state is State {
  return (
    isStateWithBackgroundLocation(state) || isStateWithLocationState(state)
  );
}

function isStateWithBackgroundLocation(
  state: unknown,
): state is StateWithBackgroundLocation {
  return (
    isObject(state) &&
    "backgroundLocation" in state &&
    isLocation(state.backgroundLocation)
  );
}

function isStateWithLocationState(
  state: unknown,
): state is StateWithLocationState {
  return (
    isObject(state) &&
    "locationState" in state &&
    isLocationState(state.locationState)
  );
}

export {
  isLocation,
  isLocationState,
  isState,
  isStateWithBackgroundLocation,
  isStateWithLocationState,
};
export type {
  LocationState,
  State,
  StateWithBackgroundLocation,
  StateWithLocationState,
};
