import { t } from "@lingui/macro";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthLevel, useAuthLevel } from "../nhs/user-auth-level";
import { getNextOnboardingStep } from "../onboarding/onboarding-view";
import { getConfig } from "../runtime-config";
import { staticUrls } from "../urls";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";

const { AUTH_IDENTITY_PROVIDER } = getConfig();

function PrivateRouteLogIn({
  requiredAuthLevel,
}: {
  requiredAuthLevel: AuthLevel;
}) {
  const { loading: isAuthLevelLoading, satisfiesAuthLevel } = useAuthLevel();
  const location = useLocation();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  if (loading || !patient) {
    return null;
  }

  if (error) {
    throw new Error(t`Something went wrong`);
  }

  const nextOnboardingStep = getNextOnboardingStep(patient);

  if (nextOnboardingStep) {
    return nextOnboardingStep === "consent" ? (
      <Navigate state={location} to={staticUrls.consent.welcomeBack} />
    ) : (
      <Navigate state={location} to={staticUrls.onboarding.index} />
    );
  }

  if (
    AUTH_IDENTITY_PROVIDER === "nhs" &&
    !isAuthLevelLoading &&
    !satisfiesAuthLevel(requiredAuthLevel)
  ) {
    return (
      <Navigate
        state={{ locationState: location }}
        to={staticUrls.nhsUpgrade}
      />
    );
  }

  return <Outlet />;
}

export { PrivateRouteLogIn };
