import { t } from "@lingui/macro";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";

function PrivateRouteConsent() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  useEffect(() => {
    if (!loading && patient && !patient.consent) {
      navigate({ pathname: "consent", search: location.search });
    }
  }, [loading, location.search, navigate, patient]);

  if (loading) {
    return null;
  }

  if (error || !patient) {
    throw new Error(t`Something went wrong`);
  }

  if (!patient.consent) {
    // will be redirected from useEffect
    return null;
  }

  return <Outlet />;
}

export { PrivateRouteConsent };
