import { useLingui } from "@lingui/react";

import { getConfig } from "./runtime-config";

const { GEOGRAPHY } = getConfig();

const staticUrls = {
  bookings: {
    new: {
      index: "/bookings/new",
      nhsLogin: "/bookings/new/nhs-login",
    },
  },
  consent: {
    confirmationsAndReminders: "/consent/confirmations-and-reminders",
    index: "/consent",
    welcomeBack: "/consent/welcome-back",
  },
  index: "/",
  logout: "/logout",
  nhsUpgrade: "/nhs-upgrade",
  onboarding: {
    consent: "/onboarding/consent",
    email: "/onboarding/email",
    index: "/onboarding",
    notificationType: "/onboarding/notification-type",
    phoneNumber: "/onboarding/phone-number",
  },
};

function isPublicPathname(pathname: string): boolean {
  const publicUrls = [staticUrls.bookings.new.nhsLogin];
  return publicUrls.includes(pathname);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useUrls() {
  const {
    i18n: { locale },
  } = useLingui();

  return {
    ...staticUrls,
    privacyPolicy:
      GEOGRAPHY === "UK"
        ? "https://zymego.uk/privacy-policy"
        : locale === "sv"
        ? "https://www.zymego.com/integritetspolicy"
        : "https://www.zymego.com/en/privacy-policy",
    termsOfUse:
      GEOGRAPHY === "UK"
        ? "https://zymego.uk/terms"
        : locale === "sv"
        ? "https://www.zymego.com/anvandarvillkor"
        : "https://www.zymego.com/en/terms",
  };
}

export { isPublicPathname, staticUrls, useUrls };
