import { completeNhsSignIn, startNhsSignIn } from "../nhs/sign-in";
import { AuthLevel } from "../nhs/user-auth-level";
import { State } from "../router/state";
import { getConfig } from "../runtime-config";
import { AppUser } from "../user/app-user";
import { userManager } from "../user-manager";

const { AUTH_IDENTITY_PROVIDER } = getConfig();

async function completeSignIn(): Promise<AppUser | undefined> {
  if (AUTH_IDENTITY_PROVIDER === "nhs") {
    return await completeNhsSignIn();
  }

  const user = await userManager.signinCallback();
  return user as AppUser | undefined;
}

async function startSignIn({
  authLevel,
  state,
}: {
  authLevel: AuthLevel;
  state?: State;
}): Promise<void> {
  return AUTH_IDENTITY_PROVIDER === "nhs"
    ? await startNhsSignIn({ authLevel, state })
    : await userManager.signinRedirect();
}

export { completeSignIn, startSignIn };
