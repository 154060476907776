import { Trans } from "@lingui/macro";
import { Dialog, Grid, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../components/button/button";
import { isFeatureEnabled } from "../feature-flags";
import { ReactComponent as ZymegoLogo } from "../icons/zymego-logo.svg";
import { colors, theme } from "../theme";
import { useUrls } from "../urls";

export function ConfirmationsAndRemindersConsentDialog() {
  const location = useLocation();
  const navigate = useNavigate();
  const urls = useUrls();

  if (!isFeatureEnabled("consentForConfirmationsAndReminders")) {
    navigate(urls.index);
    return null;
  }

  const handleButtonClick = () => {
    navigate(urls.consent.confirmationsAndReminders, { state: location.state });
  };

  return (
    <Dialog
      aria-describedby="confirmations-and-reminders-consent-dialog-description"
      aria-labelledby="confirmations-and-reminders-consent-dialog-title"
      open={true}
      scroll="body"
    >
      <Grid
        alignItems="stretch"
        container
        direction="column"
        flexGrow={1}
        justifyContent="space-between"
        minHeight="80vh"
      >
        <Grid
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap={2}
          item
          sx={{ pt: 3, textAlign: "center" }}
        >
          <ZymegoLogo style={{ fill: colors.zymegoGreen }} />
          <Typography
            align="center"
            data-cy="confirmations-and-reminders-consent-dialog-title"
            id="confirmations-and-reminders-consent-dialog-title"
            sx={{ fontWeight: "medium", pt: "10%" }}
            variant="h3"
          >
            <Trans>Welcome back!</Trans>
          </Typography>
          <Typography
            align="center"
            id="confirmations-and-reminders-consent-dialog-description"
            variant="info"
          >
            <Trans>
              We have improved our service and added new features. Therefore,
              you need to update your preferences for booking confirmations and
              reminders.
            </Trans>
          </Typography>
          <Typography
            align="center"
            id="consent-dialog-description"
            sx={{
              marginBottom: "40px",
            }}
          >
            <Trans>
              <Link
                href={urls.termsOfUse}
                sx={{
                  color: colors.zymegoDarkGreen,
                  textDecorationColor: colors.zymegoWarmGreen,
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                target="_blank"
              >
                Terms of use
              </Link>{" "}
              &{" "}
              <Link
                href={urls.privacyPolicy}
                sx={{
                  color: colors.zymegoDarkGreen,
                  textDecorationColor: colors.zymegoWarmGreen,
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                target="_blank"
              >
                Privacy policy
              </Link>
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            data-cy="confirmations-and-reminders-consent-dialog-approve-button"
            icon={theme.direction === "ltr" ? "arrowRight" : "arrowLeft"}
            onClick={handleButtonClick}
          >
            <Trans>Next</Trans>
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
