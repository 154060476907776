/* eslint-disable unicorn/no-array-callback-reference */
import type { OidcMetadata } from "oidc-client-ts";

import { GEOGRAPHIES, Geography } from "./geographies";
import { DEFAULT_VECTORS_OF_TRUST } from "./nhs/identity-proofing-level";

type AuthUserStore = "inMemoryStorage" | "localStorage";

type Config = {
  ALLOW_GUARDIANSHIP: boolean;
  API_URL: string;
  AUTH_DISABLE_PKCE: boolean;
  AUTH_EXTRA_QUERY_PARAMS:
    | Record<string, string | number | boolean>
    | undefined;
  AUTH_IDENTITY_PROVIDER: IdentityProvider;
  AUTH_METADATA: Partial<OidcMetadata> | undefined;
  AUTH_PATIENT_CLIENT_ID: string;
  AUTH_SCOPE: string;
  AUTH_URL: string;
  AUTH_USER_STORE: AuthUserStore;
  ELASTIC_APM_AGENT_ACTIVE: boolean;
  ELASTIC_APM_SERVER_URL: string | undefined;
  ENVIRONMENT: Environment;
  GEOGRAPHY: Geography;
  WEBSITE_URL: string;
};

type Environment = (typeof ENVIRONMENTS)[number];

type GetConfigOptions = {
  environment?: Record<string, string | undefined>;
  hostname?: string;
};

type IdentityProvider = "nhs" | "signicat";

const END_SESSION_URL = globalThis.location
  ? `${globalThis.location.origin}/logout/end-session`
  : undefined;
const ENVIRONMENTS = ["development", "production", "staging"] as const;
const LOGIN_URL = globalThis.location
  ? `${globalThis.location.origin}/login`
  : undefined;

function keys<T extends Record<PropertyKey, unknown>>(
  value: Record<keyof T, unknown>,
) {
  return Object.keys(value) as (keyof T)[];
}

const CONFIG_KEYS = keys<Config>({
  ALLOW_GUARDIANSHIP: true,
  API_URL: true,
  AUTH_DISABLE_PKCE: true,
  AUTH_EXTRA_QUERY_PARAMS: true,
  AUTH_IDENTITY_PROVIDER: true,
  AUTH_METADATA: true,
  AUTH_PATIENT_CLIENT_ID: true,
  AUTH_SCOPE: true,
  AUTH_URL: true,
  AUTH_USER_STORE: true,
  ELASTIC_APM_AGENT_ACTIVE: true,
  ELASTIC_APM_SERVER_URL: true,
  ENVIRONMENT: true,
  GEOGRAPHY: true,
  WEBSITE_URL: true,
});

const baseConfig = {
  ELASTIC_APM_AGENT_ACTIVE: false,
  ELASTIC_APM_SERVER_URL: undefined,
};

const seBaseConfig = {
  ...baseConfig,
  ALLOW_GUARDIANSHIP: true,
  AUTH_DISABLE_PKCE: false,
  AUTH_EXTRA_QUERY_PARAMS: undefined,
  AUTH_IDENTITY_PROVIDER: "signicat",
  AUTH_METADATA: undefined,
  AUTH_SCOPE: "openid",
  AUTH_USER_STORE: "inMemoryStorage",
  GEOGRAPHY: "SE",
} satisfies Partial<Config>;

const ukBaseConfig = {
  ...baseConfig,
  ALLOW_GUARDIANSHIP: false,
  AUTH_DISABLE_PKCE: true,
  AUTH_EXTRA_QUERY_PARAMS: { vtr: JSON.stringify(DEFAULT_VECTORS_OF_TRUST) },
  AUTH_IDENTITY_PROVIDER: "nhs",
  AUTH_METADATA: {
    end_session_endpoint: END_SESSION_URL,
  },
  AUTH_SCOPE: "openid profile email phone gp_registration_details",
  AUTH_USER_STORE: "localStorage",
  GEOGRAPHY: "UK",
} satisfies Partial<Config>;

const configs: Record<
  `${Environment}${Geography}`,
  (hostname: string) => Config
> = {
  developmentSE: () => {
    return {
      ...seBaseConfig,
      API_URL: "https://localhost:3010/patient/graphql",
      AUTH_PATIENT_CLIENT_ID: "sandbox-gentle-cheese-220",
      AUTH_URL: "https://auth.local.zymego.app/auth/open",
      ENVIRONMENT: "development",
      WEBSITE_URL: "https://www.zymego.com/?r=0",
    };
  },
  stagingSE: (hostname) => {
    const apiBaseUrl = `https://api.${hostname}`;
    return {
      ...seBaseConfig,
      API_URL: `${apiBaseUrl}/patient/graphql`,
      AUTH_IDENTITY_PROVIDER: "signicat",
      AUTH_PATIENT_CLIENT_ID: "sandbox-melancholy-eagle-485",
      AUTH_URL: `https://auth.${hostname}/auth/open`,
      ELASTIC_APM_SERVER_URL: `${apiBaseUrl}/apm`,
      ENVIRONMENT: "staging",
      WEBSITE_URL: "https://www.zymego.com/?r=0",
    };
  },
  productionSE: (hostname) => {
    const apiBaseUrl = `https://api.${hostname}`;
    return {
      ...seBaseConfig,
      API_URL: `${apiBaseUrl}/patient/graphql`,
      AUTH_IDENTITY_PROVIDER: "signicat",
      AUTH_PATIENT_CLIENT_ID: "prod-petty-goat-588",
      AUTH_URL: `https://auth.${hostname}/auth/open`,
      ELASTIC_APM_SERVER_URL: `${apiBaseUrl}/apm`,
      ENVIRONMENT: "production",
      WEBSITE_URL: "https://www.zymego.com/?r=0",
    };
  },
  developmentUK: () => {
    const authUrl = "https://auth.sandpit.signin.nhs.uk";
    const authLocalUrl = "https://localhost:3010/auth";
    return {
      ...ukBaseConfig,
      API_URL: "https://localhost:3010/patient/graphql",
      AUTH_PATIENT_CLIENT_ID: "zymego-patient-portal-nhs-login-development",
      AUTH_METADATA: {
        ...ukBaseConfig.AUTH_METADATA,
        authorization_endpoint: `${authLocalUrl}/authorize`,
        token_endpoint: `${authLocalUrl}/token?redirect_uri=${LOGIN_URL}`,
      },
      AUTH_URL: authUrl,
      ENVIRONMENT: "development",
      WEBSITE_URL: "https://www.zymego.com/en",
    };
  },
  stagingUK: (hostname) => {
    const authUrl = "https://auth.aos.signin.nhs.uk";
    const apiBaseUrl = `https://api.${hostname}`;
    const authLocalUrl = `${apiBaseUrl}/auth`;
    return {
      ...ukBaseConfig,
      API_URL: `${apiBaseUrl}/patient/graphql`,
      AUTH_PATIENT_CLIENT_ID: "zymego-patient-portal-nhs-login-integration",
      AUTH_METADATA: {
        ...ukBaseConfig.AUTH_METADATA,
        authorization_endpoint: `${authLocalUrl}/authorize`,
        token_endpoint: `${authLocalUrl}/token?redirect_uri=${LOGIN_URL}`,
      },
      AUTH_URL: authUrl,
      ELASTIC_APM_SERVER_URL: `${apiBaseUrl}/apm`,
      ENVIRONMENT: "staging",
      WEBSITE_URL: "https://zymego-2024-81fa4ae5dc0e88494a944110461.webflow.io",
    };
  },
  productionUK: (hostname) => {
    // TODO: Changing the auth in DNS when production URL is available.
    const authUrl = "https://auth.login.nhs.uk";
    const apiBaseUrl = `https://api.${hostname}`;
    const authLocalUrl = `${apiBaseUrl}/auth`;
    return {
      ...ukBaseConfig,
      API_URL: `${apiBaseUrl}/patient/graphql`,
      AUTH_PATIENT_CLIENT_ID: "zymego-patient-portal-nhs-login-production",
      AUTH_METADATA: {
        ...ukBaseConfig.AUTH_METADATA,
        authorization_endpoint: `${authLocalUrl}/authorize`,
        token_endpoint: `${authLocalUrl}/token?redirect_uri=${LOGIN_URL}`,
      },
      AUTH_URL: authUrl,
      ELASTIC_APM_SERVER_URL: `${apiBaseUrl}/apm`,
      ENVIRONMENT: "production",
      WEBSITE_URL: "https://www.zymego.com/en",
    };
  },
};

function getConfig({
  environment = import.meta.env,
  hostname = globalThis.location?.hostname ?? "localhost",
}: GetConfigOptions = {}): Config {
  const parsed = parseHostname({
    environmentGeography: environment.VITE_GEOGRAPHY,
    hostname,
  });

  const config = configs[`${parsed.environment}${parsed.geography}`](hostname);

  const overrides: Partial<Config> =
    parsed.environment === "development"
      ? {
          ...environment,
          ...(environment.VITE_ELASTIC_APM_AGENT_ACTIVE && {
            ELASTIC_APM_AGENT_ACTIVE:
              environment.VITE_ELASTIC_APM_AGENT_ACTIVE === "true",
          }),
        }
      : {};

  return validateConfig({ ...config, ...overrides });
}

function isDevelopmentHostname(hostname: string): boolean {
  const parts = hostname.split(".");
  const zymegoIndex = parts.indexOf("zymego");
  return zymegoIndex === -1 || parts[zymegoIndex - 1] === "local";
}

function isValidEnvironment(
  environment: string | undefined,
): environment is Environment {
  return ENVIRONMENTS.includes(environment as Environment);
}

function isValidGeography(
  geography: string | undefined,
): geography is Geography {
  return GEOGRAPHIES.includes(geography as Geography);
}

function parseHostname({
  environmentGeography,
  hostname,
}: {
  environmentGeography?: string;
  hostname: string;
}): { environment: Environment; geography: Geography } {
  const isDevelopment = isDevelopmentHostname(hostname);
  const parts = hostname.split(".");

  const environment = isDevelopment
    ? "development"
    : parts.map((part) => part.toLowerCase()).find(isValidEnvironment) ??
      "production";

  const geography = isDevelopment
    ? isValidGeography(environmentGeography)
      ? environmentGeography
      : "SE"
    : parts.map((part) => part.toUpperCase()).find(isValidGeography) ?? "SE";

  return { environment, geography };
}

function validateConfig(config: Config) {
  for (const key of CONFIG_KEYS) {
    if (!(key in config)) {
      throw new ReferenceError(`Missing config variable: ${key}`);
    }
  }

  return config as Config;
}

export { getConfig, isDevelopmentHostname, parseHostname };
export type { Config, GetConfigOptions };
