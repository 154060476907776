import { LocationState, State, StateWithLocationState } from "../router/state";
import { getConfig } from "../runtime-config";
import {
  AppUser,
  createUser,
  getUserStateFromLocalStorage,
} from "../user/app-user";
import { userManager } from "../user-manager";
import {
  getNhsIdentityProofingLevel,
  NhsIdentityProofingLevel,
  vectorsOfTrust,
} from "./identity-proofing-level";
import { NhsAuthenticationError } from "./nhs-authentication-error";
import { AuthLevel } from "./user-auth-level";

type NhsIdTokenClaims = {
  aud: string;
  auth_time: number;
  birthdate: string;
  exp: number;
  family_name: string;
  iat: number;
  id_status: string;
  identity_proofing_level: NhsIdentityProofingLevel;
  iss: string;
  jti: string;
  nhs_number: string;
  sub: string;
  surname: string;
  token_use: string;
  vot: string;
  vtm: string;
};

type NhsTokenResponseBody = {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
};

/**
 * NOTE: These claims depend on which scopes are requested during the NHS login.
 *
 * "Claims are not automatically provided to the partner service. All claims will need to be
 * requested and then approved by NHS login.
 *
 * The partner service should make a request to the UserInfo endpoint to obtain any of the claims
 * for the user."
 *
 * @see https://nhsconnect.github.io/nhslogin/scopes-and-claims/
 */
type NhsUserProfile = {
  aud: string;
  birthdate?: string;
  email_verified?: boolean;
  email?: string;
  family_name?: string;
  gp_registration_details?: { gp_ods_code: string };
  identity_proofing_level?: NhsIdentityProofingLevel;
  iss: string;
  nhs_number?: string;
  phone_number_pds_matched?: boolean;
  phone_number_verified?: boolean;
  phone_number?: string;
  sub: string;
};

const { AUTH_METADATA } = getConfig();

async function completeNhsSignIn(): Promise<AppUser> {
  const tokenEndpoint = AUTH_METADATA?.token_endpoint;

  if (!tokenEndpoint) {
    throw new ReferenceError(
      "Failed to complete NHS login. Token endpoint is missing.",
    );
  }

  const searchParameters = new URLSearchParams(globalThis.location.search);
  const error = searchParameters.get("error");
  const state = searchParameters.get("state");
  const userState = state ? getUserStateFromLocalStorage(state) : undefined;

  if (error) {
    const errorDescription = searchParameters.get("error_description") ?? "";
    throw new NhsAuthenticationError({ error, errorDescription, userState });
  }

  const code = searchParameters.get("code");

  if (!code) {
    throw new ReferenceError('Missing query parameter: "code"');
  }

  const query = new URLSearchParams({
    code,
    grant_type: "authorization_code",
  });

  const response = await fetch(tokenEndpoint, {
    body: query.toString(),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "POST",
  });

  const rawToken = await response.json();
  const token = rawToken as NhsTokenResponseBody & {
    id_token_claims: NhsIdTokenClaims;
    user_profile: NhsUserProfile;
  };

  const user = createUser({
    access_token: token.access_token,
    expires_at: Math.floor(Date.now() / 1000) + token.expires_in,
    id_token: token.id_token,
    profile: { ...token.id_token_claims, ...token.user_profile },
    refresh_token: token.refresh_token,
    scope: token.scope,
    token_type: token.token_type,
    userState,
  });

  await userManager.storeUser(user);
  userManager.events.load(user);
  return user;
}

async function startNhsSignIn({
  authLevel,
  state,
}: {
  authLevel: AuthLevel;
  state?: State;
}): Promise<void> {
  const user = await userManager.getUser();
  const idToken = user?.id_token;

  const identityProofingLevel = getNhsIdentityProofingLevel(authLevel);

  const locationState: LocationState = {
    pathname: "/",
    state: globalThis.history.state,
  };
  const stateWithLocationState: StateWithLocationState = {
    locationState,
    ...state,
  };

  await userManager.signinRedirect({
    extraQueryParams: {
      vtr: JSON.stringify(vectorsOfTrust[identityProofingLevel]),
      ...(idToken && { id_token: idToken }),
    },
    state: stateWithLocationState,
  });
}

export { completeNhsSignIn, startNhsSignIn };
